// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-templates-custom-page-js": () => import("./../src/templates/custom-page.js" /* webpackChunkName: "component---src-templates-custom-page-js" */),
  "component---src-templates-for-sale-js": () => import("./../src/templates/for-sale.js" /* webpackChunkName: "component---src-templates-for-sale-js" */),
  "component---src-templates-guitar-page-js": () => import("./../src/templates/guitar-page.js" /* webpackChunkName: "component---src-templates-guitar-page-js" */),
  "component---src-templates-index-page-js": () => import("./../src/templates/index-page.js" /* webpackChunkName: "component---src-templates-index-page-js" */),
  "component---src-templates-other-page-js": () => import("./../src/templates/other-page.js" /* webpackChunkName: "component---src-templates-other-page-js" */)
}

